import calculateSlope from "./calculateSlope";
import checkPointPosition from "./checkPointPosition";
import trendIntersectionWithWick from "./trendIntersectionWithWick";

export default (lows, candles) => {

    let lines = [];

    let lowestLows = [];
    let lowestLowsIndexes = [];

    for (let i = 1; i < lows.length; i++) {

        const prev = lows[i - 1];
        const next = lows[i + 1];
        const current = lows[i];

        if ((prev && next) && (prev.value > current.value && current.value < next.value)) {
            lowestLows.push(current)
            lowestLowsIndexes.push(i)
        }
    }

    for (let i = 0; i < lows.length; i++) {

        if (!lowestLowsIndexes.includes(i)) continue
        const start = lows[i];
        const startCoord = [start.index, start.value];

        for (let j = lows.length - 1; j > i; j--) {

            const end = lows[j];

            const endCoord = [end.index, end.value];
            const slope = calculateSlope(startCoord, endCoord);
            if (end.index > start.index && end.index - start.index >= 8 && slope > 0) {

                let trendBorken = false;

                const between = lows.filter((low, index) => index > start.index && end.index > index);
                const betweenCandles = candles.filter(candle => (start.index + 250) < candle.index && candle.index < (end.index + 250));

                if (betweenCandles.length > 1) {
                    const noLowerLows = between.every(point => {

                        const intersection = checkPointPosition([point.index, point.value], startCoord, endCoord);
                        return intersection !== 'bellow';
                    });

                    const noLowerCandles = betweenCandles.every(point => {

                        const intersection = checkPointPosition([point.index - 250, point.y2], startCoord, endCoord);
                        return intersection !== 'bellow';
                    });


                    const countWickTouches = betweenCandles
                        .reduce((final, current) => {

                            if (current.low !== null) {
                                const intersection = trendIntersectionWithWick(startCoord[0] + 250, startCoord[1], endCoord[0] + 250, endCoord[1], current.index, current.y1, current.y2, 'support');
                                if ((
                                    (current.y1 <= intersection.y && intersection.y <= current.y2)
                                ) && current.y2 > intersection.y) {
                                    final++;
                                }

                                if (current.y2 < intersection.y) trendBorken = true;
                            }
                            return final;
                        }, 0);


                    if (noLowerLows && noLowerCandles && countWickTouches > 0 && !trendBorken) {

                        lines.push({
                            slope,
                            startX: new Date(start.date).getTime(),
                            startY: start.value,
                            startXIndex: start.index,
                            endXIndex: end.index,
                            endX: new Date(end.date).getTime(),
                            endY: end.value
                        });
                    }

                    // }
                }
            }
        }
    }

    // console.log(lines_
    return lines;
}
