<template>
  <div id="container" style="height: calc(100% - 40px)"></div>
</template>

<script>
import ChartService from '../services/chart.service'
import ZigZag from '../technical/zigzag';

import findLinesIntersection from "../technical/findLinesIntersection";
import DiagonalResistance from '../technical/diagonalResistanceLine';
import DiagonalSupport from '../technical/diagonalSupportLine';
import HorizontalResistance from "../technical/findHorizontalResistance";

import srlines from '../technical/srLines';
import checkPointPosition from "../technical/checkPointPosition";

let chart;

export default {
  name: "ChartTest",
  props: ['symbol', 'showExtra'],
  data() {
    return {
      calculatedExtraData: false,
      isShowingSR: false,
      zigzag: [],
      diagonalResistance: [],
      diagonalSupport: [],
      supportLines: [],
      resistanceLines: [],
      circles: [],
      arcs: [],
      trianglePatterns: []
    }
  },
  methods: {
    toggleSR() {

      if (!this.isShowingSR) {
        this.isShowingSR = true;
        this.supportLines.forEach((line, index) => {

          chart.yAxis[0].addPlotLine(line);
        });

        this.resistanceLines.forEach((line, index) => {

          chart.yAxis[0].addPlotLine(line);
        });
      } else {
        this.isShowingSR = false;
        this.supportLines.forEach((line, index) => {
          chart.yAxis[0].removePlotLine(line.id);
        })

        this.resistanceLines.forEach((line, index) => {
          chart.yAxis[0].removePlotLine(line.id);
        })
      }
    },
    extraData() {

      if (this.calculatedExtraData) return;
      this.calculatedExtraData = true;
      function smoothZigzagSMA(zigzag, period) {
        return zigzag.map((point, index, arr) => {
          if (index < period) return point; // Skip until period is reached

          const window = arr.slice(index - period + 1, index + 1);
          const avgValue = window.reduce((sum, p) => sum + p.value, 0) / period;

          return {...point, value: avgValue, date: point.date}; // Update value with smoothed value
        });
      }

      const zigzag = ZigZag(this.candles, 3);
      const smooth = ZigZag(this.candles, 10);
      const {support, resistance} = srlines(JSON.parse(JSON.stringify(this.candles)));


      let circles = [];
      let highestHighs = [];

      for (let i = 1; i < zigzag.peaks.length; i++) {

        const prev = zigzag.peaks[i - 1];
        const next = zigzag.peaks[i + 1];
        const current = zigzag.peaks[i];

        if ((prev && next) && (prev.value < current.value && current.value > next.value)) {
          highestHighs.push(current)
          // circles.push({
          //   point: current.index + 250,
          //   type: 'circle',
          //   fill: 'red',
          //   r: 20
          // })
          // circles.push({
          //   type: 'circle',
          //   animation: false,
          //   point: {
          //     x: new Date(current.date).getTime(),
          //     y: current.value
          //   },
          //   r: 30,
          //   fill: 'rgba(255, 0, 0, 0.9)',
          //   stroke: 'red',
          //   zIndex: 9,
          // })
        }
      }

if (circles.length > 0) {

  this.circles.push({
    draggable: false,
    asIs: true,
    shapes: circles
  })
}

      const horizontalResistance = HorizontalResistance(zigzag.peaks.slice(-20), this.candles);
      const diagonalResistance = (DiagonalResistance(zigzag.peaks, this.candles)).sort((a, b) => b.startX - a.startX);
      const diagonalSupport = DiagonalSupport(zigzag.troughs, this.candles);

      for (let r = 0; r < horizontalResistance.length; r++) {


        let points = [{
          yAxis: 0,
          xAxis: 0,
          x: new Date(horizontalResistance[r].startDate).getTime(),
          y: horizontalResistance[r].startValue
        }, {
          yAxis: 0,
          xAxis: 0,
          x: new Date(horizontalResistance[r].endDate).getTime(),
          y: horizontalResistance[r].startValue
        }];

        if (horizontalResistance[r].highestPoint) {


          // this.arcs.push({
          //   yValue: horizontalResistance[r].startValue,
          //   lowValue: 80.29,
          //   startX: new Date(horizontalResistance[r].startDate).getTime(),
          //   endX: new Date(horizontalResistance[r].highestPoint.date).getTime(),
          // })

          this.patterns.push({
            asIs: true,
            draggable: false,
            shapes: [{
              type: 'path',
              points: [
                { x: new Date(horizontalResistance[r].startDate).getTime(), y: horizontalResistance[r].startValue, xAxis: 0, yAxis: 0 }, // Start point
                { x: new Date(horizontalResistance[r].highestPoint.date).getTime(), y: horizontalResistance[r].startValue, xAxis: 0, yAxis: 0 }  // End point
              ],
              stroke: 'red',
              strokeWidth: 2,
              // SVG Path command for creating an arc (M, A format)
              d: function () {
                var startX = this.points[0].x,
                    startY = this.points[0].y,
                    endX = this.points[1].x,
                    endY = this.points[1].y;

                var rx = 100, ry = 100;
                console.log([
                  'M', new Date(horizontalResistance[r].startDate).getTime(), horizontalResistance[r].startValue, // Move to start point
                  'A', 200, 80, 0, 0, 1, new Date(horizontalResistance[r].highestPoint.date).getTime(), horizontalResistance[r].startValue // Draw arc
                ])

                // Here we assume some radius (adjust as needed) and the arc flags
                return [
                  'M', new Date(horizontalResistance[r].startDate).getTime(), horizontalResistance[r].startValue, // Move to start point
                  'A', rx, ry, 0, 1, 1, new Date(horizontalResistance[r].highestPoint.date).getTime(), horizontalResistance[r].startValue // Draw arc
                ]
              }
            }]})
        }

        // if (horizontalResistance[r].startValue1) {
        //   points.push({
        //     yAxis: 0,
        //     xAxis: 0,
        //     x: new Date(horizontalResistance[r].endDate).getTime(),
        //     y: horizontalResistance[r].startValue1
        //   })
        //
        //   points.push({
        //     yAxis: 0,
        //     xAxis: 0,
        //     x: new Date(horizontalResistance[r].startDate).getTime(),
        //     y: horizontalResistance[r].startValue1
        //   })
        // }


        this.trianglePatterns.push({
          asIs: true,
          draggable: false,
          shapes: [{
            animation: false,
            type: 'path',
            points,
            fill: 'rgba(208,18,53, 0.5)',
            strokeWidth: 4,
            stroke: 'rgb(208,18,53)',
            dashStyle: 'solid'
          }]
        })

      }

      for (let r = 0; r < diagonalResistance.length; r++) {

        const resistanceA = diagonalResistance[r];

        // this.trianglePatterns.push({
        //   asIs: true,
        //   draggable: false,
        //   shapes: [{
        //     animation: false,
        //     type: 'path',
        //     points: [{
        //       yAxis: 0,
        //       xAxis: 0,
        //       x: resistanceA.startX,
        //       y: resistanceA.startY
        //     }, {
        //       yAxis: 0,
        //       xAxis: 0,
        //       x: resistanceA.endX,
        //       y: resistanceA.endY
        //     }],
        //     strokeWidth: 4,
        //     stroke: 'rgb(208,18,53)',
        //     dashStyle: 'solid'
        //   }]
        // })

        for (let s = 0; s < diagonalSupport.length; s++) {
          const supportA = diagonalSupport[s];

          if (supportA.startXIndex <= resistanceA.endXIndex) {
// console.log(r)

            const intersection = findLinesIntersection(
                resistanceA.startXIndex, resistanceA.startY, resistanceA.endXIndex, resistanceA.endY,
                supportA.startXIndex, supportA.startY, supportA.endXIndex, supportA.endY,
            );

            if (intersection.xRound < supportA.startXIndex || intersection.xRound < resistanceA.startXIndex) continue;

            const startCoordR = [resistanceA.startXIndex, resistanceA.startY];
            const startCoordS = [supportA.startXIndex, supportA.startY];
            const endCoord = [intersection.xRound, intersection.y];

            // if (r === 24) {
            //   console.log('###',resistanceA.startXIndex + 1, resistanceA.endXIndex)
            //   console.log(this.candles.slice(resistanceA.startXIndex + 1, resistanceA.endXIndex - resistanceA.startXIndex))
            // }

            const isResistanceBroken = !this.candles.slice(resistanceA.startXIndex + 1, resistanceA.endXIndex - 1).every(point => {

              if (point.high === null) return true;
              const intersection = checkPointPosition([point.index, point.high], startCoordR, endCoord);
              return intersection !== 'above';
            });

            let t = this.candles.slice(supportA.startXIndex + 1, supportA.endXIndex - 1);

            const isSupportBroken = !this.candles.slice(supportA.startXIndex + 1, supportA.endXIndex - 1).every(point => {

              if (point.low === null) return false;
              const intersection = checkPointPosition([point.index, point.low], startCoordS, endCoord);
              return intersection !== 'bellow';
            });


            const slopeA = Math.abs(resistanceA.slope);
            const slopeB = Math.abs(supportA.slope);
            const slopeDiff = Math.abs((slopeB - slopeA) / slopeA)
            if (slopeDiff < 0.5) {
              // if (slopeDiff < 0.5 && !isSupportBroken) {

              // const endX = new Date(this.candles[intersection.xRound + 250].date).getTime();
              // this.trianglePatterns.push({
              //   asIs: true,
              //   draggable: false,
              //   shapes: [{
              //     animation: false,
              //     type: 'path',
              //     points: [{
              //       yAxis: 0,
              //       xAxis: 0,
              //       x: resistanceA.startX,
              //       y: resistanceA.startY
              //     }, {
              //       yAxis: 0,
              //       xAxis: 0,
              //       x: endX,
              //       y: intersection.y
              //     }],
              //     strokeWidth: 4,
              //     stroke: 'rgb(208,18,53)',
              //     dashStyle: 'solid'
              //   }, {
              //     draggable: false,
              //     animation: false,
              //     type: 'path',
              //     points: [{
              //       yAxis: 0,
              //       xAxis: 0,
              //       x: supportA.startX,
              //       y: supportA.startY
              //     }, {
              //       yAxis: 0,
              //       xAxis: 0,
              //       x: endX,
              //       y: intersection.y
              //     }],
              //     strokeWidth: 4,
              //     stroke: 'rgb(4,128,91)',
              //     dashStyle: 'solid'
              //   }]
              // })
            }

          }
        }
      }

      this.patterns = this.patterns.concat(this.trianglePatterns);
      this.patterns = this.patterns.concat(this.circles);


      // smooth.zigzag.forEach((zig, index) => {
      //
      //   if (smooth.zigzag[index - 1]) {
      //     this.patterns.push({
      //       asIs: true,
      //       draggable: false,
      //       shapes: [{
      //         animation: false,
      //         type: 'path',
      //         points: [{
      //           yAxis: 0,
      //           xAxis: 0,
      //           x: new Date(smooth.zigzag[index - 1].date).getTime(),
      //           y: smooth.zigzag[index - 1].value
      //         }, {
      //           yAxis: 0,
      //           xAxis: 0,
      //           x: new Date(zig.date).getTime(),
      //           y: zig.value
      //         }],
      //         strokeWidth: 2,
      //         stroke: 'rgb(255,222,9)',
      //         dashStyle: 'solid'
      //       }]
      //     })
      //   }
      // })

      // zigzag.zigzag.forEach((zig, index) => {
      //
      //   if (zigzag.zigzag[index - 1]) {
      //     this.patterns.push({
      //       asIs: true,
      //       draggable: false,
      //       shapes: [{
      //         animation: false,
      //         type: 'path',
      //         points: [{
      //           yAxis: 0,
      //           xAxis: 0,
      //           x: new Date(zigzag.zigzag[index - 1].date).getTime(),
      //           y: zigzag.zigzag[index - 1].value
      //         }, {
      //           yAxis: 0,
      //           xAxis: 0,
      //           x: new Date(zig.date).getTime(),
      //           y: zig.value
      //         }],
      //         strokeWidth: 2,
      //         stroke: 'rgb(9,115,255)',
      //         dashStyle: 'solid'
      //       }]
      //     })
      //   }
      // })
      //

      // console.log(intersection)

      for (let r of this.diagonalResistance) {

        for (let s of this.diagonalSupport) {

          let absS = Math.abs(s.slope);
          let absR = Math.abs(r.slope);
          let max = Math.max(absS, absR);
          let min = Math.min(absS, absR);

          if (min / max > 0.9) {
            // console.log(min/max, r.slope, s.slope)
          }
        }
      }

      let supportLines = support.sort((a, b) => b.strength - a.strength).slice(0, 20).map((line, index) => ({
        value: line.avg,
        id: `s-${index}`,
        color: 'rgba(4,128,91,0.3)',
        width: 2,
        zIndex: 10,
      }));

      let resistanceLines = resistance.sort((a, b) => b.strength - a.strength).slice(0, 20).map((line, index) => ({
        id: `r-${index}`,
        value: line.avg,
        color: 'rgba(208,18,53,0.3)',
        width: 2,
        zIndex: 10,
      }));

      this.zigzag = zigzag;
      this.supportLines = supportLines;
      this.resistanceLines = resistanceLines;
    },
    drawChart() {


      const symbol = this.symbol;
      const candles = this.candles.map(candle => [new Date(candle.date).getTime(), candle.open, candle.high, candle.low, candle.close]);

      const volume = this.candles.map(candle => ({
        x: new Date(candle.date).getTime(),
        y: candle.volume,
        color: candle.close > candle.open ? 'rgba(4,128,91, .8)' : 'rgba(208,18,53,0.8)'
      }))

      let patterns = this.patterns.map(pattern => {

        if (pattern.asIs) return pattern;
        const components = pattern.components;

        return {
          draggable: false,
          shapes: [{
            animation: false,
            type: 'path',
            points: [{
              yAxis: 0,
              xAxis: 0,
              x: new Date(components.resistance.startDate).getTime(),
              y: components.resistance.startValue
            }, {
              yAxis: 0,
              xAxis: 0,
              x: new Date(components.resistance.endDate).getTime(),
              y: components.resistance.startValue
            }],
            strokeWidth: 4,
            stroke: 'rgba(208,18,53, 0)',
            dashStyle: 'solid'
          }, {
            draggable: false,
            animation: false,
            type: 'path',
            points: [{
              yAxis: 0,
              xAxis: 0,
              x: new Date(components.support.startDate).getTime(),
              y: components.support.startValue
            }, {
              yAxis: 0,
              xAxis: 0,
              x: new Date(components.support.endDate).getTime(),
              y: components.support.endValue
            }],
            strokeWidth: 4,
            stroke: 'rgba(4,128,91, 1)',
            dashStyle: 'solid'
          }]
        }
      });

      console.log(patterns)

      chart = Highcharts.chart('container', {
        title: false,
        legend: false,
        plotOptions: {
          series: {
            hover: false
          },
          candlestick: {
            groupPadding: 5,
            color: 'rgb(208,18,53)',
            lineColor: 'rgb(208,18,53)',
            upColor: 'rgb(4,128,91)',
            upLineColor: 'rgb(4,128,91)'
          }
        },
        tooltip: {
          shared: false,
          useHTML: true,
          backgroundColor: 'transparent',
          formatter: function () {
            var s = '<div style="font-size: 14px;">' + Highcharts.dateFormat('%A, %b %e, %Y', this.x) + ' \u00A0'; // Format the date
            let point = this.point;
            return `<div style="border-radius: 5px; padding: 5px 10px; backdrop-filter: blur(2px); background: rgba(0, 0, 0, 0.05); color: var(--textColor)">${s}<span>O</span><span style="color: ${point.open > point.close ? 'rgb(4,128,91)' : 'rgb(208,18,53)'}">${point.open}</span> \u00A0<span>H</span><span style="color: ${point.open > point.close ? 'rgb(4,128,91)' : 'rgb(208,18,53)'}">${point.high}</span> \u00A0<span>L</span><span style="color: ${point.open > point.close ? 'rgb(4,128,91)' : 'rgb(208,18,53)'}">${point.low}</span> \u00A0<span>C</span><span style="color: ${point.open > point.close ? 'rgb(4,128,91)' : 'rgb(208,18,53)'}">${point.close}</span></div></div>`;
          },
          shadow: false,
          positioner: function (labelWidth, labelHeight, point) {
            return {
              x: 5,
              y: 5
            };
          },
        },
        xAxis: {
          ordinal: true,
          type: 'datetime', // Specify that the x-axis is a datetime axis
          tickInterval: null,
          dateTimeLabelFormats: {
            day: '%e %b %Y', // Format for days
            week: '%e %b %Y', // Format for weeks
            month: '%b %Y', // Format for months
            year: '%Y' // Format for years
          },
          crosshair: {
            snap: true,
            color: 'var(--notficationBorderColor)'
          },
          labels: {
            style: {
              color: 'var(--textColor)'
            }
          },
          gridLineColor: '#393b434f'
        },
        yAxis: [{
          labels: {
            style: {
              color: 'var(--textColor)'
            }
          },
          plotLines: [],
          opposite: true,
          title: false,
          height: '85%',
          crosshair: {
            enabled: true, // Enable crosshair for the Y-axis
            snap: false,
            color: 'var(--notficationBorderColor)',
            dashStyle: 'solid',
            width: 1,
            label: {
              enabled: true, // Show label on the Y-axis crosshair
              format: '{value:.2f}', // Format the label to show two decimals
              backgroundColor: 'var(--notficationBorderColor)',
              borderWidth: 1,
              borderRadius: 3,
              boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, .2)',
              style: {
                color: 'white',
                fontWeight: 'bold'
              }
            }
          },
          gridLineColor: '#393b434f',
        }, {
          opposite: true,
          labels: {
            style: {
              color: 'var(--textColor)'
            },
            x: 10
          },
          title: {
            text: false
          },
          top: '87%',
          height: '13%',
          offset: 0,
          gridLineColor: '#393b434f',
          lineWidth: 0
        }],
        chart: {
          events: {
            load: function () {
              const chart = this;

              const text = symbol + ',1D'; // Your watermark text
              const x = chart.plotLeft + (chart.plotWidth / 2); // Center x position
              const y = chart.plotTop + (chart.plotHeight / 2); // Center y position


              // Convert timestamps to pixels
              var startXPixel = chart.xAxis[0].toPixels(1709074800000);
              var endXPixel = chart.xAxis[0].toPixels(1725314400000);

              // Y-axis pixel positions (can also use chart.yAxis[0].toPixels())
              var startYPixel = chart.yAxis[0].toPixels(103.52); // Arbitrary y-values
              var endYPixel = chart.yAxis[0].toPixels(82);


              // chart.renderer.path(['M', startXPixel, startYPixel, 'A', 200, endYPixel, 1, 0, 0, endXPixel, startYPixel])
              //     .attr({ stroke: '#ff00ff' })
              //     .add();

              // chart.renderer.arc(200, 150, 100, 50, -Math.PI, 0).attr({
              //   fill: '#FCFFC5',
              //   stroke: 'black',
              //   'stroke-width': 1
              // }).add();
              // Add the watermark text
              chart.renderer.text(text, x, y)
                  .css({
                    color: 'rgba(255, 255, 255, 0.15)', // Light color for watermark
                    fontSize: '64px',
                    textAlign: 'left',
                    transform: 'translateX(-10%)'
                  })
                  .attr({
                    zIndex: 5 // Ensure it's above other elements
                  })
                  .add();


              let isDragging = false;
              let startY;
              let extremes;

              Highcharts.addEvent(chart.container, 'mousedown', function (e) {

                const chartX = e.chartX;
                const plotWidth = parseFloat(chart.plotBackground.element.getAttribute('width'));

                if (chartX > plotWidth) {

                  if (!isDragging) {
                    const yAxis = chart.yAxis[0];
                    extremes = yAxis.getExtremes();
                    isDragging = true;
                    startY = e.chartY;
                  }
                }
              });

              Highcharts.addEvent(document, 'mousemove', function (e) {
                if (isDragging) {

                  const deltaY = (e.chartY - startY) / 10;
                  const yAxis = chart.yAxis[0];
                  yAxis.setExtremes((extremes.min - deltaY), extremes.max + (deltaY))


                  const lastExtremes = yAxis.getExtremes();
                  if (lastExtremes.min === undefined || lastExtremes.max === undefined) {
                    yAxis.setExtremes(extremes.dataMin, extremes.dataMax)
                  }
                }
              });

              Highcharts.addEvent(document, 'mouseup', function () {
                isDragging = false;
              });

            }
          },
          animation: false,
          backgroundColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 1,
              y2: 1
            },
            stops: [
              [0, '#1f1d1e'],
              [1, 'rgb(42,39,41)']
            ]
          },
          panKey: false,
          panning: {
            enabled: true,
            type: 'xy'
          },
          panType: 'xy',
          pinchType: 'xy',
          zooming: {
            mouseWheel: {
              enabled: true,
              type: 'x'
            }
          },
        },
        rangeSelector: {
          selected: 0.2
        },
        series: [{
          type: 'candlestick',
          name: null,
          pointWidth: 1,
          clip: false,
          pointRange: 10,
          data: candles,
        }, {
          animation: false,
          type: 'column',
          name: 'Volume',
          borderWidth: 0,
          borderRadius: 0,
          data: volume,
          yAxis: 1,
        }],
        annotations: patterns,
      });
    }
  },
  async mounted() {
    const {candles, patterns} = await ChartService.getChartBySymbol(this.symbol);
    this.candles = candles.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).map((_, index) => {

      if (_.low !== null) {

        _.y1 = _.low;
        _.y2 = Math.min(_.open, _.close);
        _.y3 = Math.max(_.open, _.close);
        _.y4 = _.high;
      }
      _.index = index;
      _.originalIndex = index;
      _.formattedDate = _.date.split('T')[0];

      return _;
    });
    this.patterns = patterns;


    try {

      this.$nextTick(() => {
        if (this.showExtra) this.extraData();

        this.drawChart();
      })
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>

</style>
