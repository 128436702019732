import calculateSlope from "./calculateSlope";
import checkPointPosition from "./checkPointPosition";
import trendIntersectionWithWick from "./trendIntersectionWithWick";

export default (highs, candles) => {

    let lines = [];
    let highestHighs = [];
    let highestHighsIndexes = [];

    for (let i = 1; i < highs.length; i++) {

        const prev = highs[i - 1];
        const next = highs[i + 1];
        const current = highs[i];

        if ((prev && next) && (prev.value < current.value && current.value > next.value)) {
            highestHighs.push(current)
            highestHighsIndexes.push(i)
        }
    }

    for (let i = 0; i < highs.length; i++) {

        if (!highestHighsIndexes.includes(i)) continue;
        const start = highs[i];
        const startCoord = [start.index, start.value];
        for (let j = highs.length - 1; j > i; j--) {

            const end = highs[j];

            // if (start.date.includes('2024-07-10') && end.date.includes('2024-08-19')) {

            // const rangeCandles = candles.slice(start.index + 1, end.index - start.index);
            const endCoord = [end.index, end.value];
            const slope = calculateSlope(startCoord, endCoord);
            // console.log(start.date, end.date, slope)
            if (end.index > start.index && end.index - start.index >= 8 && slope < 0) {

                const between = highs.filter((low, index) => index > start.index && end.index > index);
                const betweenCandles = candles.filter(candle => (start.index + 250) < candle.index && candle.index < (end.index + 250));

                const noHigherHighs = between.every(point => {

                    const intersection = checkPointPosition([point.index, point.value], startCoord, endCoord);
                    return intersection !== 'above';
                });

                const noHigherCandles = betweenCandles.every(point => {

                    const intersection = checkPointPosition([point.index - 250, point.y3], startCoord, endCoord);
                    return intersection !== 'above';
                });


                if (noHigherHighs && noHigherCandles) {
                    const countWickTouches = betweenCandles
                        .reduce((final, current) => {

                            if (current.high !== null) {
                                const intersection = trendIntersectionWithWick(startCoord[0] + 250, startCoord[1], endCoord[0] + 250, endCoord[1], current.index, current.y3, current.y4, 'resistance');
                                if (
                                    ((current.y3 <= intersection.y && intersection.y <= current.y4) || (current.y4 / intersection.y) > 0.9) &&
                                    current.y3 < intersection.y
                                ) {
                                    final++;
                                } else {
                                }
                            }
                            return final;
                        }, 0);


                    if (countWickTouches > 0) {
                        lines.push({
                            slope,
                            touchingWicks: countWickTouches,
                            startX: new Date(start.date).getTime(),
                            startXIndex: start.index,
                            endXIndex: end.index,
                            startY: start.value,
                            endX: new Date(end.date).getTime(),
                            endY: end.value
                        });
                    }
                }
            }
        }
    }
    // }

    // console.log(lines_
    return lines;
}
