function calculateSlope(point1, point2) {
    const [x1, y1] = point1;
    const [x2, y2] = point2;

    // Check for vertical line (division by zero)
    if (x2 - x1 === 0) {
        throw new Error('Slope is undefined (vertical line).');
    }

    return (y2 - y1) / (x2 - x1);
}

export default calculateSlope;
