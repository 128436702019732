function assignPointValues(zigzagObject, point) {

    // return zigzagObject;
    return Object.assign(zigzagObject, {
        y1: point.y1,
        y2: point.y2,
        y3: point.y3,
        y4: point.y4,
        extendedHigh: point.extendedHigh,
        extendedLow: point.extendedLow,
        open: point.open,
        high: point.high,
        low: point.low,
        close: point.close,
        volume: point.volume,
        formattedDate: point.formattedDate,
        originalIndex: point.originalIndex,
        mas: point.sma,
        vma: point.vsma
    })
}



class ZigZag {
    constructor(data, deviation = 5) {
        this.data = data;
        this.deviation = deviation / 100;
        this.zigzag = [];
        this.peaks = [];
        this.troughs = [];
    }

    calculate() {
        let trend = 0;
        let lastPeak = {value: -Infinity, index: -1};
        let lastTrough = {value: Infinity, index: -1};

        for (let i = 0; i < this.data.length; i++) {
            const high = this.data[i].high;
            const low = this.data[i].low;
            const date = this.data[i].date;

            if (trend >= 0) {
                if (high > lastPeak.value) {
                    lastPeak = assignPointValues({value: high, index: i, date, type: 'high'}, this.data[i]);
                } else if (low < lastPeak.value * (1 - this.deviation)) {
                    this.zigzag.push(lastPeak);
                    this.peaks.push(lastPeak);
                    lastTrough = assignPointValues({value: low, index: i, date, type: 'low'}, this.data[i]);
                    trend = -1;
                }
            } else {
                if (low < lastTrough.value) {
                    lastTrough = assignPointValues({value: low, index: i, date, type: 'low'}, this.data[i]);
                } else if (high > lastTrough.value * (1 + this.deviation)) {
                    this.zigzag.push(lastTrough);
                    this.troughs.push(lastTrough);
                    lastPeak = assignPointValues({value: high, index: i, date, type: 'high'}, this.data[i]);
                    trend = 1;
                }
            }
        }

        // Add the last point
        if (trend >= 0) {
            this.zigzag.push(lastPeak);
            this.peaks.push(lastPeak);
        } else {
            this.zigzag.push(lastTrough);
            this.troughs.push(lastTrough);
        }

        let trends = [];

        for (let i = 0; i < this.zigzag.length; i++) {

            const start = this.zigzag[i];
            const end = this.zigzag[i + 1];

            if (end) {

                let low = Math.min(start.value, end.value);
                let high = Math.max(start.value, end.value);

                let trend = {
                    startDate: start.date,
                    endDate: end.date,
                    low,
                    high,
                    direction: start.value < end.value ? 1 : -1,
                    change: (end.value - start.value) / start.value
                }

                trends.push(trend);
            }
        }

        return {
            trends,
            zigzag: this.zigzag,
            peaks: this.peaks,
            troughs: this.troughs
        };
    }
}

export default (candles, deviation = 5) => {

    const zz = new ZigZag(candles, deviation);
    return zz.calculate();
}
