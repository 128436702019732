function findLinesIntersection(x1, y1, x2, y2, x3, y3, x4, y4) {
    let m1 = (y2 - y1) / (x2 - x1);
    let m2 = (y4 - y3) / (x4 - x3);

    let b1 = y1 - m1 * x1;
    let b2 = y3 - m2 * x3;

    // Check if the lines are parallel
    if (m1 === m2) {
        console.log('The lines are parallel and do not intersect.');
        return null;
    } else {

        let x = (b2 - b1) / (m1 - m2);
        let y = m1 * x + b1;

        return {x, y, xRound: Math.round(x)};
    }
}

export default findLinesIntersection;
