function checkPointPosition(point, lineStart, lineEnd) {
    const [x1, y1] = lineStart;
    const [x2, y2] = lineEnd;
    const [x, y] = point;

    // Calculate the slope (m) of the line
    const slope = (y2 - y1) / (x2 - x1);

    // Calculate the y-intercept (b) of the line: y = mx + b => b = y - mx
    const yIntercept = y1 - slope * x1;
    const yOnLine = slope * x + yIntercept;

    // console.log(y, yOnLine)
    if (y > yOnLine) {
        return "above";
    } else if (y < yOnLine) {
        return "below";
    } else {
        return "on the line";
    }
}

export default checkPointPosition;
