function calculateSlopeAndIntercept(x1, y1, x2, y2) {
    const m = (y2 - y1) / (x2 - x1);
    const b = y1 - m * x1;
    return {m, b};
}
function trendIntersectionWithWick(x1, y1, x2, y2, verticalX, y3, y4, type) {
    const {m, b} = calculateSlopeAndIntercept(x1, y1, x2, y2);
    const x = verticalX;

    const y = m * x + b;

    if (y >= Math.min(y3, y4) && y <= Math.max(y3, y4)) {
        return {x, y};
    } else {

        if (type === 'support') {
            if (y > y3 && y > y4) return {x, y}
        } else {
            if (y < y3 && y < y4) return {x, y}
        }
        return {x: null, y};
    }
}

export default trendIntersectionWithWick;
