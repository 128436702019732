function isWithinPercentageRange(target, value, percentage = 3) {

    value = Math.round(value);
    target = Math.round(target);

    if (target < 20) {

        percentage = 0.25;
    } else if (target > 20 && target < 100) {
        percentage = 1;
    } else if (target > 100 && target < 500) {
        percentage = 3;
    } else {
        percentage = 5;
    }

    const range = (target * percentage) / 100;
    const lowerBound = target - range;
    const upperBound = target + range;

    return value >= lowerBound && value <= upperBound;
}

function checkIfHorizontalLineCrossesTwoHighs(first, second) {
    const middle = ((first.y4 - first.y3) / 2) + first.y3;

    return (
        (first.y4 >= second.y4 && first.y3 <= second.y3) ||
        (first.y4 > second.y4 && first.y3 > second.y3 && middle <= second.y4) ||
        (first.y4 < second.y4 && first.y3 > second.y3) ||
        (isWithinPercentageRange(first.y4, second.y4))
    );
}

function countConsecutiveGroups(arr) {
    if (arr.length === 0) {
        return 0;
    }


    // Sort the array (if not guaranteed to be sorted)
    arr.sort((a, b) => a - b);

    let ranges = [];
    let count = 1; // Start with one group
    let rangeEnd = arr[0]; // Initialize the end of the range

    for (let i = 1; i < arr.length; i++) {
        if (arr[i] > rangeEnd + 10) {
            // Current number is out of range, start a new group
            count++;
            rangeEnd = arr[i]; // Reset the end of the range
            ranges.push(arr[i])
        } else {
            // Extend the range
            rangeEnd = Math.max(rangeEnd, arr[i]);
        }
    }

    return {count, ranges};
}

function findResistance(peaks, candles, lastATR = null) {


    const firstPeak = peaks[0];
    const lastPeak = peaks[peaks.length - 1];

    let betweenPeaks = peaks.filter(peak => peak.originalIndex > firstPeak.originalIndex && peak.originalIndex < lastPeak.originalIndex)

    const isHorizontalLine = checkIfHorizontalLineCrossesTwoHighs(firstPeak, lastPeak);
    if (firstPeak.formattedDate === '2024-07-05' && lastPeak.formattedDate === '2024-08-29') {

        function coords(point) {
            return {y1: point.y1, y2: point.y2, y3: point.y3, y4: point.y4}
        }

        function log() {
            const middle = ((firstPeak.y4 - firstPeak.y3) / 2) + firstPeak.y3;
            console.log('YES', isHorizontalLine, isWithinPercentageRange(firstPeak.y4, lastPeak.y4))
            console.log('1', (firstPeak.y4 >= lastPeak.y4 && firstPeak.y3 <= lastPeak.y3));
            console.log('2', (firstPeak.y4 > lastPeak.y4 && firstPeak.y3 > lastPeak.y3 && middle < lastPeak.y4));
            console.log('3', (firstPeak.y4 < lastPeak.y4 && firstPeak.y3 > lastPeak.y3));
            console.log(firstPeak.y4, lastPeak.y4)
            console.log(firstPeak.y3, lastPeak.y3)
            console.log(coords(firstPeak), coords(lastPeak))
            console.log(firstPeak.formattedDate, lastPeak.formattedDate)
            console.log(betweenPeaks.filter(peak => checkIfHorizontalLineCrossesTwoHighs(firstPeak, peak)).map(peak => peak.formattedDate))
        }

        if (firstPeak.formattedDate === '2024-07-05' && lastPeak.formattedDate === '2024-08-29') {
            // log()
        }
    }


    if (!isHorizontalLine) return null;

    const noHigherPeaks = betweenPeaks.filter(peak => (!isWithinPercentageRange(firstPeak.y4, peak.y3) && firstPeak.y4 < peak.y3) || peak.y1 > firstPeak.y4).map(d => [d.formattedDate, d.y3]);

    if (noHigherPeaks.length > 0) return null;

    let touchingPoints = [
        firstPeak.index,
        ...betweenPeaks.filter(peak => checkIfHorizontalLineCrossesTwoHighs(firstPeak, peak)).map(peak => peak.index),
        lastPeak.index
    ];

    let touchingCandles = [
        firstPeak,
        ...betweenPeaks.filter(peak => checkIfHorizontalLineCrossesTwoHighs(firstPeak, peak)),
        lastPeak
    ];

    let touchingPointsDates = [
        firstPeak.formattedDate,
        ...betweenPeaks.filter(peak => checkIfHorizontalLineCrossesTwoHighs(firstPeak, peak)).map(peak => peak.formattedDate),
        lastPeak.formattedDate
    ];

    return {
        type: 'resistance',
        direction: 'horizontal',
        startDateFormatted: firstPeak.formattedDate,
        endDateFormatted: lastPeak.formattedDate,
        startDate: firstPeak.date,
        endDate: lastPeak.date,
        startValue: firstPeak.value,
        endValue: firstPeak.value,
        startIndex: firstPeak.index,
        endIndex: lastPeak.index,
        startMAs: firstPeak.mas,
        touchingCandles,
        length: lastPeak.index - firstPeak.index,
        candleIndexes: peaks.map(peak => peak.index),
        touchingPoints,
        touchingPointsDates
    };
}

function mergeLines(lines, candles) {

    let merged = [];
    let skipLineIndex = [];

    const sortByLength = lines.sort((a, b) => b.length - a.length).map((_, index) => {

        _.index = index;
        return _;
    });

    for (let line of sortByLength) {

        if (!skipLineIndex.includes(line.index)) {
            const closestOverlappingLines = lines.filter((overlapping, index) => {

                if (overlapping.startIndex !== line.startIndex && overlapping.startValue !== line.startValue && !overlapping.ignore) {

                    const diffBetweenLines = (overlapping.startValue / line.startValue);
                    return diffBetweenLines >= 0.98 && diffBetweenLines <= 1.02;
                }

                return false;
            });


            if (closestOverlappingLines.length > 0) {

                closestOverlappingLines.forEach(overlapping => {
                    skipLineIndex.push(overlapping.index)
                })
                const latestLine = closestOverlappingLines.sort((a, b) => b.endIndex - a.endIndex)?.[0];
                line.endIndex = latestLine.endIndex;
                line.endDate = latestLine.endDate;
                line.endDateFormatted = latestLine.endDateFormatted;


                const lowestLine = closestOverlappingLines.sort((a, b) => a.startValue - b.endValue)?.[0];
                line.startValue1 = lowestLine.startValue;

                line.touchingPoints = line.touchingPoints.concat([...latestLine.touchingPoints, ...lowestLine.touchingPoints]);
                line.touchingPoints = countConsecutiveGroups([...new Set(line.touchingPoints)].sort((a, b) => a - b))?.ranges;
                line.candleIndexes = line.touchingPoints;

                const filterCandles = candles.filter((_, index) => line.touchingPoints.includes(index));
                line.touchingCandles = filterCandles;
                line.touchingPointsDates = filterCandles.map(candle => candle.formattedDate);


                line.touchingCandles.unshift(candles[line.startIndex])
                line.touchingPointsDates.unshift(candles[line.startIndex].formattedDate)
                line.touchingPoints.unshift(line.startIndex)

                if (line.touchingPoints.length > 3) {
                    let alreadySet = false;

                    let highestPoint = line.touchingCandles[1];
                    line.touchingCandles.forEach((candle, index) => {

                        if (index > 0 && index < line.touchingCandles.length - 1 && !alreadySet) {

                            if (candle.high > highestPoint.high) {
                                alreadySet = true;
                                highestPoint = candle;
                            }
                        }
                    });

                    line.highestPoint = highestPoint;
                } else if (line.touchingPoints.length === 3) {
                    line.highestPoint = line.touchingPoints[1];
                } else if (line.touchingPoints.length < 3) {

                    line.highestPoint = null;
                }

            }
            merged.push(line);
        }
    }

    return merged;
}

function HorizontalResistance(data, candles) {

    let resistanceIndexes = [];
    let resistances = [];

    data = data.map((d, index) => {

        d.pointIndex = index;
        return d;
    });

    let highs = data.filter(point => point.type === 'high');

    for (let i = 1; i < highs.length; i++) {


        // if (!isStageTwo(highs[i])) continue;

        const precedingLow = data[highs[i].pointIndex - 1];
        if (precedingLow) {

            if (highs[i].originalIndex - precedingLow.originalIndex === 1) continue;
        }
        const subSet = [highs[i], ...highs.slice(i + 1, highs.length)].reverse();
        // Find resistance lines - horizontal highs.
        for (let j = 0; j < subSet.length; j++) {

            const peaks = subSet.slice(j, subSet.length).reverse();

            if (peaks.length > 1) {

                const period = peaks[peaks.length - 1].index - peaks[0].index;

                if (period >= 10) {

                    let resistance = findResistance(peaks, candles);
                    if (resistance) resistances.push(resistance);
                }
            }
        }
    }


    // return resistances;
    return mergeLines(resistances, candles);
}

export default HorizontalResistance;
